export interface MenuItem {
    label: string;
    link: string;
    icon?: string;
}

export const secondaryMenu: MenuItem[] = [
    {
        label: "GitHub",
        link: "https://github.com/spark-engine-ai",
        icon: "github fab",
    },
    {
        label: "Feedback",
        link: "https://forms.gle/6Kuxn7AciB1e5ioJ7",
        icon: "comment",
    },
];
