import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Text, Flex, Image, Checkbox, Loader } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { useCallback, useState, useEffect } from 'react';
import { openChatPanel } from '../../../store/settings-ui';
import Cookies from 'js-cookie';

export default function WelcomePopup() {
  const dispatch = useDispatch();
  const [neverShow, setNeverShow] = useState(false);
  const [opened, { close }] = useDisclosure(!Cookies.get('nsa-popup'));
  const [isImageLoaded, setImageLoaded] = useState(false);

  const openSettings = useCallback(() => {
    if(neverShow) {
      Cookies.set('nsa-popup', 'true', { expires: 365 }); // Setting the cookie to expire after 1 year
    }
    dispatch(openChatPanel());
    close();
  }, [dispatch, close, neverShow]);

  const closeAndSetCookie = useCallback(() => {
    if(neverShow) {
      Cookies.set('nsa-popup', 'true', { expires: 365 });
    }
    close();
  }, [close, neverShow]);

  return (
    <>
      <Modal opened={opened} onClose={closeAndSetCookie} withCloseButton={true} trapFocus={false} title="Welcome">
        <Text style={{fontSize:'12pt'}}>Customize your experience with our mod menu!</Text>
        <Flex justify="center">
          {!isImageLoaded && <Loader variant="dots" className="exclude-inversion" />}
          <Image
            className="exclude-inversion"
            src='modmenu.gif'
            alt='Your description'
            style={{marginTop:'3vh', width:'85%', borderRadius:'7px', display: isImageLoaded ? 'block' : 'none' }}
            onLoad={() => setImageLoaded(true)}
          />
        </Flex>
        <Flex justify='center' style={{gap: '10px', marginTop:'3vh', marginBottom:'3vh'}}>
          <Button className="exclude-inversion" onClick={openSettings}>Use Mods</Button>
          <Button className="exclude-inversion exclude-inversion-border-black exclude-inversion-text-black" onClick={closeAndSetCookie} color='transparent' variant='outline'>Continue with current settings</Button>
        </Flex>
        <Flex justify='left'>
        <Checkbox
          checked={neverShow}
          label='Never show again'
          onChange={(event) => setNeverShow(event.currentTarget.checked)}
        />
        </Flex>
      </Modal>
    </>
  );
}
