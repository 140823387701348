import {
  IconActivity,
  IconAlertCircle,
  IconBinaryOff,
  IconBinaryTree2,
  IconBinaryTree,
  IconBinary,
  IconCloudComputing,
  IconCloudDataConnection,
  IconError404Off,
  IconError404,
  IconHttpConnect,
  IconHttpDelete,
  IconHttpGet,
  IconHttpHead,
  IconHttpOptions,
  IconHttpPatch,
  IconHttpPost,
  IconHttpPut,
  IconHttpQue,
  IconHttpTrace,
  IconLoadBalancer,
  IconNetworkOff,
  IconNetwork,
  IconApple,
  IconBug,
  IconGhost,
  IconBook,
  IconBallpen,
  IconScale,
  IconBrain,
  IconBat,
  IconBarbell,
  IconGridDots,
  IconBolt,
  IconCode,
  IconGrillFork,
  IconAnkh,
  IconBusinessplan,
  IconSkull,
  IconZoomPan,
  IconRun,
  IconBuildingCastle,
  IconGizmo,
  IconMovie,
  IconSettings,
  IconBrandLinkedin,
  IconBrandYcombinator,
  IconHandGrab,
  IconSkateboarding,
  IconQuestionMark,
  IconRocket,
  IconAxe,
  IconPlant,
  IconFlower,
  IconMusic,
  IconStethoscope,
  IconWand,
  IconCrown,
  IconBone,
  IconFish,
  IconWoman,
  IconCoin,
  IconBath,
} from "@tabler/icons-react";

const icons = {
  activity: IconActivity,
  alertCircle: IconAlertCircle,
  binaryOff: IconBinaryOff,
  binaryTree2: IconBinaryTree2,
  binaryTree: IconBinaryTree,
  binary: IconBinary,
  cloudComputing: IconCloudComputing,
  cloudDataConnection: IconCloudDataConnection,
  error404Off: IconError404Off,
  error404: IconError404,
  httpConnect: IconHttpConnect,
  httpDelete: IconHttpDelete,
  httpGet: IconHttpGet,
  httpHead: IconHttpHead,
  httpOptions: IconHttpOptions,
  httpPatch: IconHttpPatch,
  httpPost: IconHttpPost,
  httpPut: IconHttpPut,
  httpQue: IconHttpQue,
  httpTrace: IconHttpTrace,
  loadBalancer: IconLoadBalancer,
  networkOff: IconNetworkOff,
  network: IconNetwork,
  apple: IconApple,
  bug: IconBug,
  book: IconBook,
  ghost: IconGhost,
  pen: IconBallpen,
  scale: IconScale,
  brain: IconBrain,
  bat: IconBat,
  barbell: IconBarbell,
  gridDots: IconGridDots,
  bolt: IconBolt,
  code: IconCode,
  fork: IconGrillFork,
  ankh: IconAnkh,
  businessPlan: IconBusinessplan,
  skull: IconSkull,
  zoomPan: IconZoomPan,
  run: IconRun,
  buildingCastle: IconBuildingCastle,
  gizmo: IconGizmo,
  movie: IconMovie,
  settings: IconSettings,
  brandLinkedin: IconBrandLinkedin,
  brandYcombinator: IconBrandYcombinator,
  stethoscope: IconStethoscope,
  wand: IconWand,
  crown: IconCrown,
  bone: IconBone,
  fish: IconFish,
  handGrab:IconHandGrab,
  skateboarding:IconSkateboarding,
  questionMark:IconQuestionMark,
  rocket:IconRocket,
  axe:IconAxe,
  plant:IconPlant,
  flower:IconFlower,
  music: IconMusic,
  woman: IconWoman,
  coin: IconCoin,
  bath: IconBath,
};

export default icons;
