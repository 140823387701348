import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../store';
import { openSystemPromptPanel, openOpenAIApiKeyPanel, openChatPanel } from '../../store/settings-ui';
import { Page } from '../page';
import { useOption } from '../../core/options/use-option';
import { isProxySupported } from '../../core/chat/openai';
import { supabase } from '../../lib/server/supabase';
import WelcomePopup from '../settings/popup/index';

const Container = styled.div`
    flex-grow: 1;
    padding-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Work Sans", sans-serif;
    line-height: 1.7;
    gap: 1rem;
`;

export default function LandingPage(props: any) {
  const dispatch = useAppDispatch();

  const onConnectButtonClick = useCallback(() => dispatch(openChatPanel()), [dispatch]);

  return (
      <Page id={'landing'} showSubHeader={true}>
        <Container>
          <p>
            <FormattedMessage defaultMessage={'Welcome to SparkGPT!'} description="The expanded version of ChatGPT" />
          </p>
          <Button className='exclude-inversion-text-black exclude-inversion' size="xs" variant="light" compact onClick={onConnectButtonClick}>
            <FormattedMessage defaultMessage={'Go to mods'} />
          </Button>
        </Container>
        <WelcomePopup />
      </Page>
  );
}
