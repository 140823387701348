import React, { useState, useEffect, useContext } from 'react';
import { IconSun, IconMoon } from '@tabler/icons-react';
import Cookies from 'js-cookie';

const InvertButton = () => {
  // useState to manage the inverted value
  const [inverted, setInverted] = useState(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme ? JSON.parse(storedTheme) : false;
  });

  useEffect(() => {
    // define the handler
    const handleStorageChange = () => {
      const storedTheme = localStorage.getItem('theme');
      const newTheme = storedTheme ? JSON.parse(storedTheme) : false;
      setInverted(newTheme);
      toggleInvertStyle(newTheme);
    };

    // attach the event listener
    window.addEventListener('storage', handleStorageChange);

    // clean up function
    return () => {
      // remove the event listener
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []); // only run once when the component mounts

  const handleButtonClick = () => {
    setInverted(prevInverted => {
      const newTheme = !prevInverted;
      localStorage.setItem('theme', JSON.stringify(newTheme));
      return newTheme;
    });
    window.location.reload();
  };

  useEffect(() => {
  toggleInvertStyle(inverted);
}, [inverted]);


  const toggleInvertStyle = (isInverted) => {
    const css = 'html { -webkit-filter: invert(1); -moz-filter: invert(1); -o-filter: invert(1); -ms-filter: invert(1); }';
    const excludeCss = '.exclude-inversion { -webkit-filter: invert(1); -moz-filter: invert(1); -o-filter: invert(1); -ms-filter: invert(1); }';
    const excludeInversionFillBlack = '.exclude-inversion-fill-black { -webkit-filter: invert(1); -moz-filter: invert(1); -o-filter: invert(1); -ms-filter: invert(1); }';
    const excludeInversionFillWhite = '.exclude-inversion-fill-white { -webkit-filter: invert(1); -moz-filter: invert(1); -o-filter: invert(1); -ms-filter: invert(1); }';
    const excludeInversionText = '.exclude-inversion-text { color: white !important; -webkit-filter: invert(1); -moz-filter: invert(1); -o-filter: invert(1); -ms-filter: invert(1);  }';
    const excludeInversionTextBlack = '.exclude-inversion-text-black { color: black !important; }';
    const excludeInversionTextWhite = '.exclude-inversion-text-white { color: white !important; }';
    const excludeInversionBorderBlack = '.exclude-inversion-border-black { border-color: black !important; }';
    const excludeInversionBorderWhite = '.exclude-inversion-border-white { border-color: white !important; }';

    const head = document.getElementsByTagName('head')[0];
    const style = document.getElementById('invertStyle');

    if (isInverted && !style) {
      const newStyle = document.createElement('style');
      newStyle.id = 'invertStyle';
      newStyle.type = 'text/css';
      newStyle.appendChild(document.createTextNode(css + excludeCss + excludeInversionFillBlack + excludeInversionFillWhite + excludeInversionText + excludeInversionTextBlack + excludeInversionTextWhite + excludeInversionBorderBlack + excludeInversionBorderWhite));
      head.appendChild(newStyle);

      const elements = document.querySelectorAll<HTMLElement>('.exclude-inversion-fill-black, .exclude-inversion-fill-white');
      elements.forEach((el) => {
        const computedColor = window.getComputedStyle(el).backgroundColor;
        const rgba = computedColor.match(/(\d+),\s*(\d+),\s*(\d+),\s*(\d\.\d+|\d+)/);

        if (rgba) {
          let [r, g, b, alpha] = rgba.slice(1).map((v) => Number(v));

          if (el.classList.contains('exclude-inversion-fill-black')) {
            [r, g, b] = [r * alpha, g * alpha, b * alpha].map(Math.round);
          } else {  // exclude-inversion-fill-white
            [r, g, b] = [r * alpha + (1 - alpha) * 255, g * alpha + (1 - alpha) * 255, b * alpha + (1 - alpha) * 255].map(Math.round);
          }

          el.style.backgroundColor = `rgb(${r}, ${g}, ${b})`;
        }
      });
    } else if (!isInverted && style) {
      style.remove();
    }
  };


  return (
    <div>
      <button onClick={handleButtonClick}
      style={{
        color:'white',
        background:'transparent',
        cursor:'pointer',
        border:'0px',
        marginTop:'0.5vh',
      }}>
        {inverted ? <IconMoon size={16}/> : <IconSun size={16}/>}
      </button>
    </div>
  );
};

export default InvertButton;
