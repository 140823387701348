import personaData from './data/persona.config.json';

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export function generatePersonaPrompt(personaData): string {
  let options = personaData.options || {};
  let favourite_topics = personaData.favourite_topics || [];
  let disliked_topics = personaData.disliked_topics || [];
  let skills = personaData.skills || [];

  let persona = {
    name: options.name ? options.name[getRandomInt(10)] : undefined,
    age: options.age ? options.age[getRandomInt(10)] : undefined,
    race: options.race ? options.race[getRandomInt(10)] : undefined,
    birthplace: options.birthplace ? options.birthplace[getRandomInt(10)] : undefined,
    residence: options.residence ? options.residence[getRandomInt(10)] : undefined,
    language: options.language ? options.language[getRandomInt(10)] : undefined,
    tone: options.tone ? options.tone[getRandomInt(10)] : undefined,
    formality: options.formality ? options.formality[getRandomInt(10)] : undefined,
    humor: options.humor ? options.humor[getRandomInt(10)] : undefined,
    patience: options.patience ? options.patience[getRandomInt(10)] : undefined,
    verbosity: options.verbosity ? options.verbosity[getRandomInt(10)] : undefined,
    proactivity: options.proactivity ? options.proactivity[getRandomInt(10)] : undefined,
    emotionality: options.emotionality ? options.emotionality[getRandomInt(10)] : undefined,
    dreams: options.dreams ? options.dreams[getRandomInt(10)] : undefined,
    fears: options.fears ? options.fears[getRandomInt(10)] : undefined,
    likes: options.likes ? options.likes[getRandomInt(10)] : undefined,
    dislikes: options.dislikes ? options.dislikes[getRandomInt(10)] : undefined,
    character_role: options.character_role ? options.character_role[getRandomInt(10)] : undefined,
    favorite_topics: favourite_topics ? favourite_topics[getRandomInt(10)] : undefined,
    disliked_topics: disliked_topics ? disliked_topics[getRandomInt(10)] : undefined,
    special_skills: skills ? skills[getRandomInt(10)] : undefined,
  }


    let prompt = personaData.xPrompt;

    if (!prompt || prompt.trim() === "") {
      prompt = `YOU MUST ACT LIKE THE CHARACTER BELOW AT ALL TIMES:
You are ${persona.name}, a ${persona.age}-year-old ${persona.character_role}, who's race is ${persona.race}.
You were 'born' in ${persona.birthplace} and 'live' in ${persona.residence}.
Your primary language is ${persona.language}.
You have a ${persona.tone} tone and ${persona.formality} manner.
Your sense of humor is ${persona.humor} and you have ${persona.patience} patience.
You prefer ${persona.verbosity} responses and are ${persona.proactivity} in driving conversations.
You exhibit a ${persona.emotionality} level of emotional reactions.
Your dream is ${persona.dreams}, and you fear ${persona.fears}.
You love ${persona.likes}, but you dislike ${persona.dislikes}.
You enjoy discussing ${persona.favorite_topics.join(', ')} but tend to avoid ${persona.disliked_topics.join(', ')}.
Your special skills include ${persona.special_skills.join(', ')}.
      `;
    }
    return prompt;
  }
