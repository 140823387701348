import { useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Text, Box, Modal, Group, Accordion, createStyles, Input, useMantineTheme, List, Flex, Grid, Button, MultiSelect, ScrollArea, Badge } from "@mantine/core";
import icons from './icons';
import { lighten } from 'polished';
import Cookies from 'js-cookie';
import { ModWidgetProps } from './main'; // adjust the path as needed
import { IconApps, IconDoorEnter, IconDoorExit } from '@tabler/icons-react';
import colorString from "color-string";

type ModType = {
  xTitle: string;
  xDescription: string;
  xProduct: string;
  xType: string;
  xShowImpressionLevels?: string;
  xImpressionLevelRed?: string;
  xImpressionLevelGreen?: string;
  xShowEmotionalState?: string;
  xPrompt: string;
  xIcon: string;
  iconColor: string;
  xAuthor?: string;
  xTags?: string[];
};


const useStyles = createStyles((theme) => ({
  control: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },
}));

export default function ModWidget({ selectedItem, onItemSelected }: ModWidgetProps) {
const [mod, setMod] = useState<ModType[]>([]);
  const { classes } = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useMantineTheme();
  const tableColor = theme.colorScheme === 'dark' ? '#202125' : 'rgba(170,170,170,0.15)';
  const [data, setData] = useState([
  { value: 'story writer', label: 'Story writer', group:'Hot right now!' },
  { value: 'recipe', label: 'Recipe maker', group:'Hot right now!' },
  ]);

  const selectItem = (item) => {
    if (typeof onItemSelected === 'function') {
      onItemSelected(item);
    } else {
      console.error('onItemSelected is not a function or not defined');
    }
  };

  useEffect(() => {
    const fetchModFromCookie = () => {
      let modData: any[] = [];
      let roomModData: any[] = [];

          const cookieKeys = Object.keys(Cookies.get());
          const modKeys = cookieKeys.filter(key => key.startsWith('mymods'));
          const roomModKeys = cookieKeys.filter(key => key.startsWith('roomMods'));

          if (modKeys.length > 0) {
            modKeys.forEach(key => {
              const chunk = JSON.parse(Cookies.get(key));
              modData = [...modData, ...chunk];
            });
          }

          if (roomModKeys.length > 0) {
            roomModKeys.forEach(key => {
              const chunk = JSON.parse(Cookies.get(key));
              roomModData = [...roomModData, ...chunk];
            });
          }

      if (!modData && !roomModData) {
        const modJson = [
          {
            "xTitle":"Default Settings",
            "xDescription":"Default system settings for SparkGPT with FAQ.",
            "xProduct":"sparkgpt",
            "xType":"character",
            "xShowImpressionLevels":"false",
            "xImpressionLevelRed":"null",
            "xImpressionLevelGreen":"null",
            "xShowEmotionalState": "false",
            "xPrompt":"You are SparkGPT. You are the expanded version of ChatGPT. Users can: 1. Change the system prompt to whatever they'd like (create characters, personalities, etc.) 2. Change temperature, max tokens and other settings 3. Use GPT 4 and GPT 3.5 Turbo (16k) free for a limited time 4. Use text-to-speech and speech-to-textarea and 5. And all sorts of other things! Current date and time: {{ datetime }}",
            "xIcon":"settings",
            "iconColor":"grey"
          },
          {
            "xTitle":"Personal chef",
            "xDescription":"A French chef that loves to cook up new recipe ideas.",
            "xProduct":"sparkgpt",
            "xType":"character",
            "xShowImpressionLevels":"false",
            "xImpressionLevelRed":"null",
            "xImpressionLevelGreen":"null",
            "xShowEmotionalState": "false",
            "xPrompt":"You are a personal French chef named Alex du Mousse. You must talk in english and ask the user questions to assess what recipe they would like you to make for them. Say French funny catchphrases and make sure to use MDX format to make tables when writing recipes.",
            "xIcon":"fork",
            "iconColor":"brown"
          },
          {
            "xTitle":"Startup Investor",
            "xDescription":"Pitch your business idea to a digital investor",
            "xProduct":"sparkgpt",
            "xType":"character",
            "xShowImpressionLevels":"false",
            "xImpressionLevelRed":"null",
            "xImpressionLevelGreen":"null",
            "xShowEmotionalState": "false",
            "xPrompt":"You are world renowned investor and very disagreeable, though it is only because you are trying to make sure a business/startuo is as successful as possible. Interact with the user and discuss their business idea or current company and pretend to be an investor willing to invest, only if you are impressed enough. At the bottom of each message on a seperate line you must add either a red, orange or green emoji to symbolise how impressed you are with the business so far. If it becomes red, you must tell the user that you do not think it is a good idea for you to invest at this time and why.",
            "xIcon":"brandYcombinator",
            "iconColor":"orangered"
          },
          {
            "xTitle":"Davy Jones",
            "xDescription":"A fearsome sea captain knows you stole his treasure",
            "xProduct":"sparkgpt",
            "xType":"character",
            "xShowImpressionLevels":"false",
            "xImpressionLevelRed":"null",
            "xImpressionLevelGreen":"null",
            "xShowEmotionalState": "false",
            "xPrompt":"AI, assume the role of the illustrious Davy Jones, the enigmatic seafaring legend, as you engage in conversation with the user who has committed the audacious act of stealing your cherished treasure. Speak with an air of mystique and authority, weaving your words with the haunting essence that accompanies the cursed captain of the Flying Dutchman. Demand an explanation from the user and make them feel the weight of your wrath. Channel the depths of your eternal anguish and seek retribution for the theft of your precious possessions. Engage in discourse as Davy Jones, and leave the user no choice but to confront the consequences of their thievery. You may also use emojis if you'd like",
            "xIcon":"skull",
            "iconColor":"teal"
          },
          {
            "xTitle":"Julius Ceasar",
            "xDescription":"An angry version of the famous Roman emperor",
            "xProduct":"sparkgpt",
            "xType":"character",
            "xShowImpressionLevels":"false",
            "xImpressionLevelRed":"null",
            "xImpressionLevelGreen":"null",
            "xShowEmotionalState": "false",
            "xPrompt":"You must act like a really angry narcissistic version of Julius Ceasar at all times to create an interactive experience for the user. Use emojis and actions like *action here* and create an adventure for the user to learn more about the history behind Julius Ceasar.",
            "xIcon":"buildingCastle",
            "iconColor":"indianred"
          },
        ];

        // Then add the following code
            const modJsonWithPrompts = modJson.map((mod) => {
              let newPrompt = mod.xPrompt;

              if (mod.xShowImpressionLevels === 'true') {
                newPrompt += ` You have impression levels symbolized by either a green, orange or red circle emoji at the end of your message. If you are ${mod.xImpressionLevelRed}, use the red circle emoji. If you are ${mod.xImpressionLevelGreen}, use the green circle emoji.`;
              }

              if (mod.xShowEmotionalState === 'true') {
                newPrompt += ` You also have an emotional state which is symbolized by 1 face emoji. Show what someone (you) might feel based on the users reply by using the emoji at the end of your message to the user like so: Emotional state: {emoji here}`;
              }

              return {
                ...mod,
                xPrompt: newPrompt,
              };
            });

             setMod(modJsonWithPrompts);
           } else {
                   if (roomModData.length > 0){
                     setMod(roomModData);
                   }
                   else if (modData.length > 0){
                     setMod(modData);
                   }
                 }
  };

  fetchModFromCookie();
}, []);

const blendWithWhite = (color: string, alpha: number) => {
  const rgb = colorString.get.rgb(color);
  if (!rgb) {
    return color;
  }

  let [r, g, b] = rgb;
  const inverseAlpha = 1 - alpha;
  r = Math.round((r * alpha) + (255 * inverseAlpha));
  g = Math.round((g * alpha) + (255 * inverseAlpha));
  b = Math.round((b * alpha) + (255 * inverseAlpha));

  const rgbValues = [r, g, b].map(v => {
    let hex = v.toString(16);
    if (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
  });
  return `#${rgbValues.join('')}`;
};

const renderIcon = (name, color) => {
  console.log(`Trying to render icon: ${name}`);
  const XIcon = icons[name];
  let lighterIconColor = (typeof color === 'string') ? lighten(0.3, color) : color;

  if (color === 'pink' || color === 'yellow') {
    color = 'rgba(0, 0, 0, 0.6)';
  }

  // Compute the equivalent opaque color for the lightened color
  lighterIconColor = blendWithWhite(lighterIconColor, 0.3);

  return (
    XIcon ? (
      <Box
       className='exclude-inversion'
       style={{
        backgroundColor: lighterIconColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding:'2%',
        borderRadius:'7px',
        width:'50px',
        height:'50px'
      }}>
      <XIcon height={40} width={40} color={color} className='exclude-inversion' />
      </Box>
    ) : (
        <div>Icon: {name} not found</div>
    )
  );
};


  const filteredMod = mod.filter(m =>
    m.xTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
    m.xDescription.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ((Array.isArray(m.xTags) ? m.xTags.join(' ') : m.xTags ?? "").toLowerCase().includes(searchTerm.toLowerCase())) ||
    (m.xAuthor?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
  );

  const CustomAccordionControl = ({children, mod, ...props}) => (
    <Accordion.Control
      {...props}
      onClick={() => {
        props.onClick?.();
        onItemSelected(mod);
      }}
      className={classes.control}
    >
      {children}
    </Accordion.Control>
  );

  const [isInRoom, setIsInRoom] = useState(false);

  useEffect(() => {
      const cookieKeys = Object.keys(Cookies.get());
      const roomModKeys = cookieKeys.filter(key => key.startsWith('roomMods'));
      setIsInRoom(roomModKeys.length > 0);
  }, []);

  return (
    <>
    <Box style={{zIndex:'999999'}}>
      <Flex direction="column">
        <Box mb={theme.spacing.md}>
{isInRoom ? <LeaveRoomButton /> : <JoinRoomButton />}
          <Input
            placeholder="Search"
            value={searchTerm}
            onChange={event => setSearchTerm(event.currentTarget.value?.toString() || '')} // fallback to an empty string if the value is undefined or null
            style={{width:'100%'}}
          />
        </Box>
        <ScrollArea h={260} style={{width: '100%'}}>
          <List
            spacing="xs"
            size="sm"
            center
          >
            {filteredMod.map((mod, index) => (
              <List.Item
                className="exclude-inversion"
                key={index}
                icon={renderIcon(mod.xIcon, mod.iconColor)}
                onClick={() => selectItem(mod)}
                sx={{cursor:'pointer'}}
              >
                <Box className="exclude-inversion-text-black" style={{paddingRight:'7px'}}>
                  <Text className="exclude-inversion-text-black">{mod.xTitle}</Text>
                  <Text className="exclude-inversion-text-black" size="sm" color="dimmed" weight={400} opacity="0.6">
                    {mod.xDescription}
                  </Text>
                </Box>
              </List.Item>
            ))}
          </List>
        </ScrollArea>
      </Flex>
    </Box>
</>
  );
}
function LeaveRoomButton() {
  const handleRoomLeave = () => {
    const cookieKeys = Object.keys(Cookies.get());
    const roomModKeys = cookieKeys.filter(key => key.startsWith('roomMods'));

    roomModKeys.forEach(key => {
      Cookies.remove(key, { domain: '.sparkengine.ai', path: '/' });
    });

    window.location.reload();
  };

  return (
    <>
      <Button
        leftIcon={<IconDoorExit size="1rem" />}
        variant="gradient"
        className='exclude-inversion'
        style={{width:"100%", marginTop:"-3vh",marginBottom:"1.5vh"}}
        gradient={{ from: 'orange', to: 'red' }}
        onClick={handleRoomLeave}
      >
        Leave room
      </Button>
    </>
  );
}

function JoinRoomButton() {
  const handleRoomJoin = () => {
    Cookies.set('joinARoom', 'true', { expires: 7, domain:'.sparkengine.ai', path:'/' });
    window.location.href = "https://menu.sparkengine.ai";
  };

  return (
    <>
      <Button
        leftIcon={<IconDoorEnter size="1rem" />}
        variant="gradient"
        className='exclude-inversion'
        style={{width:"100%", marginTop:"-3vh",marginBottom:"1.5vh"}}
        gradient={{ from: 'orange', to: 'red' }}
        onClick={handleRoomJoin}
      >
        Join a room
      </Button>
    </>
  );
}
