import { FormattedMessage } from "react-intl";
import Plugin from "../core/plugins";
import { PluginDescription } from "../core/plugins/plugin-description";
import { OpenAIMessage, Parameters } from "../core/chat/types";
import { generatePersonaPrompt } from './persona';
import personaData from './data/persona.config.json'

export const defaultSystemPrompt = `
You are SparkGPT. You are the expanded version of ChatGPT.

Users can:

- Change the system prompt to whatever they'd like (create characters, personalities, etc.)
- Change temperature, max tokens and other settings
- Use GPT 4 and GPT 3.5 Turbo (16k) free for a limited time
- Use text-to-speech and speech-to-textarea
- And all sorts of other things!

Current date and time: {{ datetime }}`.trim();

export interface SystemPromptPluginOptions {
    systemPrompt: string;
}

export class SystemPromptPlugin extends Plugin<SystemPromptPluginOptions> {

    describe(): PluginDescription {
        return {
            id: "system-prompt",
            name: "Mod Menu",
            options: [
                {
                    id: "systemPrompt",
                    defaultValue: defaultSystemPrompt,
                    displayOnSettingsScreen: "system",
                    resettable: true,
                    scope: "chat",
                    renderProps: {
                        type: "textarea",
                        description: <p>

                        </p>,
                    },
                    displayInQuickSettings: {
                        name: "System Prompt",
                        displayByDefault: true,
                        label: (value, options, context) => {return context.intl.formatMessage({defaultMessage: "Customize system prompt"})},
                    },
                },
                {
                    id: "uploadSystemPrompt",
                    defaultValue: "",
                    displayOnSettingsScreen: "system",
                    resettable: false,
                    scope: "chat",
                    renderProps: {
                        type: "file",
                        description: <p><FormattedMessage defaultMessage={"Make a new chat before rerolling for a better experience. This is currently in beta and soon anyone will be able to create and share their own mods!"} /></p>,
                    },
                    displayInQuickSettings: {
                        name: "Build your chatbot!",
                        displayByDefault: true,
                        label: (value, options, context) => {return context.intl.formatMessage({defaultMessage: "Upload system prompt config"})},
                    },
                },

            ],
        };
    }

    async preprocessModelInput(messages: OpenAIMessage[], parameters: Parameters): Promise<{ messages: OpenAIMessage[]; parameters: Parameters; }> {
        const output = [
          {
              role: 'system',
              content: (this.options?.systemPrompt ||
                        localStorage.getItem('systemPrompt') ||
                        defaultSystemPrompt)
                  .replace('{{ datetime }}', new Date().toLocaleString()),
          },
            ...messages,
        ];

        return {
            messages: output,
            parameters,
        };
    }
}
