import { useState } from 'react';
import { Box, Flex, Button, Text, Paper, useMantineTheme } from "@mantine/core";
import ModWidget from './modWidget';
import icons from './icons';
import { lighten } from 'polished';
import { IconRobot, IconDice3 } from '@tabler/icons-react';
import colorString from "color-string";

export interface ModWidgetProps {
  selectedItem: any; // replace "any" with the actual type of the "selectedItem" prop
  onItemSelected: (item: any) => void; // replace "any" with the actual type of the "item" parameter
}

const blendWithWhite = (color: string, alpha: number) => {
  const rgb = colorString.get.rgb(color);
  if (!rgb) {
    return color;
  }

  let [r, g, b] = rgb;
  const inverseAlpha = 1 - alpha;
  r = Math.round((r * alpha) + (255 * inverseAlpha));
  g = Math.round((g * alpha) + (255 * inverseAlpha));
  b = Math.round((b * alpha) + (255 * inverseAlpha));

  const rgbValues = [r, g, b].map(v => {
    let hex = v.toString(16);
    if (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
  });
  return `#${rgbValues.join('')}`;
};

const renderIcon = (name, color) => {
  console.log(`Trying to render icon: ${name}`);
  const XIcon = icons[name];
  let lighterIconColor = (typeof color === 'string') ? lighten(0.3, color) : color;

  if (color === 'pink' || color === 'yellow') {
    color = 'rgba(0, 0, 0, 0.6)';
  }

  // Compute the equivalent opaque color for the lightened color
  lighterIconColor = blendWithWhite(lighterIconColor, 0.3);

  return (
    XIcon ? (
      <Box
      className="exclude-inversion"
      style={{
          backgroundColor: lighterIconColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '5%',
          width:'60px',
          height:'60px',
          borderRadius: '7px'
        }}>
        <XIcon width={50} height={50} color={color} className="exclude-inversion"/>
      </Box>
    ) : (
      <div>Icon: {name} not found</div>
    )
  );
};

export default function ModWidgetMain({ selectedItem, onItemSelected, onRerollChatbot }) {
  const [isOpen, setIsOpen] = useState(true);
  const [changesMade, setChangesMade] = useState(false);
  const theme = useMantineTheme();

  const handleRerollChatbot = () => {
    onRerollChatbot();
    setChangesMade(false);
  };

  const handleItemSelected = (item) => {
    onItemSelected(item);
    setIsOpen(false);
    setChangesMade(true);
  };

  return (
    <Box>
      <Box style={{ padding: '1%', maxHeight: '100vh' }}>
        {isOpen && (
          <Box>
            <ModWidget selectedItem={selectedItem} onItemSelected={item => { handleItemSelected(item); setIsOpen(false); }} />
          </Box>
        )}
        {!isOpen && selectedItem && (
          <Flex align="flex-start" justify="center">
            <Paper
              style={{
                cursor: 'pointer',
                padding:'1%',
                width:"100%",
              }}
              onMouseEnter={(e) => e.currentTarget.style.boxShadow = theme.shadows.xs}
              onMouseLeave={(e) => e.currentTarget.style.boxShadow = 'none'}
              onClick={() => setIsOpen(true)}
            >
              <Flex>
                <Box className="exclude-inversion">
                  {renderIcon(selectedItem.xIcon, selectedItem.iconColor)}
                </Box>
                <Box style={{padding:'12px', paddingTop:'0', paddingBottom:'0'}}>
                  <Text>{selectedItem.xTitle}</Text>
                  <Text size="sm" color="dimmed" weight={400} opacity="0.6">
                    {selectedItem.xDescription}
                  </Text>
                </Box>
              </Flex>
            </Paper>
          </Flex>
        )}
      </Box>
      {!isOpen && (
        <Flex justify="left">
          <Button
            variant="outline"
            onClick={() => setIsOpen(true)}
            sx={{ marginTop: '3vh', marginLeft:'7px' }}
          >
            <IconRobot size={24}/> Mod Menu
          </Button>
          {changesMade && (
            <>
              <Button
                sx={{ marginTop: '3vh', marginLeft: '7px' }}
                variant="outline"
                color="red"
                onClick={handleRerollChatbot}
              >
                Apply
              </Button>
            </>

          )}
          {selectedItem.options && (
            <>
            {!changesMade && (
            <Button
              sx={{ marginTop: '3vh', marginLeft: '1vw' }}
              variant="gradient"
              gradient={{ from: 'indigo', to: 'cyan' }}
              onClick={handleRerollChatbot}
            >
              <IconDice3 size={24}/> Reroll chatbot
            </Button>
          )}
          </>
          )}
        </Flex>
      )}
    </Box>
  );
}
